import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDe_AT from '@angular/common/locales/de-AT';
import localeDe_CH from '@angular/common/locales/de-CH';
import localeEn from '@angular/common/locales/en';
import localeEn_GB from '@angular/common/locales/en-GB';
import localeEn_IE from '@angular/common/locales/en-IE';
import localeEs from '@angular/common/locales/es';
import localeEs_AR from '@angular/common/locales/es-AR';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeLb from '@angular/common/locales/lb';
import localeLt from '@angular/common/locales/lt';
import localeNl from '@angular/common/locales/nl';
import localeRo from '@angular/common/locales/ro';
import { NgModule } from '@angular/core';
import { I18nModule, Locale, provideL10n } from '@fmnts/i18n';
import 'moment/locale/de';
import 'moment/locale/de-at';
import 'moment/locale/de-ch';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/lb';
import 'moment/locale/lt';
import 'moment/locale/nl';
import 'moment/locale/ro';
import Translations from '../../../app/src/translations';
import { PublicLanguageSource } from './public-l10n';

// Register locale data
// This is needed for angular pipes to work properly
registerLocaleData(localeDe);
registerLocaleData(localeDe_AT);
registerLocaleData(localeDe_CH);
registerLocaleData(localeEn);
registerLocaleData(localeEn_GB);
registerLocaleData(localeEn_IE);
registerLocaleData(localeEs);
registerLocaleData(localeEs_AR);
registerLocaleData(localeFr);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localeLb);
registerLocaleData(localeLt);
registerLocaleData(localeNl);
registerLocaleData(localeRo);

const defaultLocale = Locale.LocaleId('en-GB');
const locales = [
  defaultLocale,
  Locale.LocaleId('en'),
  Locale.LocaleId('en-IE'),
  Locale.LocaleId('de'),
  Locale.LocaleId('de-AT'),
  Locale.LocaleId('de-CH'),
  Locale.LocaleId('de-DE'),
  Locale.LocaleId('es'),
  Locale.LocaleId('es-AR'),
  Locale.LocaleId('fr'),
  Locale.LocaleId('hu'),
  Locale.LocaleId('it'),
  Locale.LocaleId('lb'),
  Locale.LocaleId('lt'),
  Locale.LocaleId('nl'),
  Locale.LocaleId('ro'),
] as const;

/**
 * Module that provides the localization for the application.
 */
@NgModule({
  providers: [
    provideL10n({
      locale: {
        default: defaultLocale,
        supported: locales,
      },
      translate: {
        translations: Translations,
        order: [
          PublicLanguageSource.DonorFeedback,
          PublicLanguageSource.Browser,
          PublicLanguageSource.Default,
        ],
      },
    }),
  ],
  exports: [I18nModule],
})
export class PublicLocalizationModule {}
